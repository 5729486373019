// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const url = window.location.href;
const matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
// const baseUrl = 'https://site1.dev-v3.memo.codium.co';

const baseUrl = matches[0].replace('4200', '8000');
// const websocketUrl = baseUrl.replace('http', 'ws') + '/ws/';
export const environment = {
  production: false,
  baseUrl,
};

export const featureFlag = {
  ddoc: false,
  ddoc_enable_feature_26: false,
  ddoc_enable_feature_28: false,
  timestamp_memo_enable: false,
  ndid: false,
  login_adfs_enable: false,
  maintenance_mode: false,
  maintenance_message_th: '',
  maintenance_message_en: '',
  sms_otp: false,
  email_otp: false,
  thaicom: false,
  self_service_form: false,
  google_client_id: '',
  lark_app_id: '',
  export_memo_google_drive_enable: false,
};
// websocketUrl,
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
